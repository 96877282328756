import { createUserWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../firebasecreds";
import '../style/emailsignup.css'

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signUp = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="creatorwrapper">
      <form onSubmit={signUp}>
        <h1 className="creatorhead">Geh nach Hause digga</h1>
        <input
          className="creatoremail"
          type="email"
          placeholder="no email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <input
          className="creatorpassword"
          type="password"
          placeholder="go away"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <button className="creatorbutton" type="submit">buoh</button>
      </form>
    </div>
  );
};

export default SignUp;