// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyD40ieQGJQmgj__RzuqoUGvIejDVbO48zg",
  authDomain: "awfulcompany-edd29.firebaseapp.com",
  databaseURL: "https://awfulcompany-edd29-default-rtdb.firebaseio.com",
  projectId: "awfulcompany-edd29",
  storageBucket: "awfulcompany-edd29.appspot.com",
  messagingSenderId: "118159628287",
  appId: "1:118159628287:web:da077cc2ec942ec4f979ca",
  measurementId: "G-99B1CGHHDG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { app, auth, analytics };
