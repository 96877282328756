import '../style/ancestralcat.css';
import ancestralcat from '../assets/face.png';

function AncestralCat() {
    return(
        <div className='ancestralwrapper'>
            <h1>cat</h1>
            <image className='catimage' src={ancestralcat}></image>
        </div>
    );
}

export default AncestralCat;
