import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react';
import '../style/emaillogin.css';
import '../firebasecreds'
import { auth } from '../firebasecreds';

function EmailLogIn() {
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");

    const signIn = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                console.log(userCredential);
            })

            .catch((error) => {

                console.log(error);

            });

    };

    return (
        <div className='wrapperbox'>
            <form onSubmit={signIn} className='loginbox'>
                <h1 className='wrapperheader'>Passt</h1>
                <input type='email' className='thisisemail' placeholder='email' value={email} onChange={(e) => setEmail(e.target.value)}></input>
                <input type='password' className='thisispassword' placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)}></input>
                <a href='https://evil.com' className='passforgot'>Vergessen?</a>
                <button type='submit' className='submitbutton'>you know</button>
            </form>
        </div>
    );
}


export default EmailLogIn;

