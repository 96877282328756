import React from 'react';
import './App.css';
import './firebasecreds';
import { DropdownMenu } from './components/dropdownmenu';
import { Navbar, NavItem } from './components/navbar';
import { ReactComponent as SkullIcon} from './assets/icons/skull.svg';
import { ReactComponent as HouseIcon} from './assets/icons/house.svg';
import { ReactComponent as UserIcon} from './assets/icons/user.svg';
import { ReactComponent as DiscordIcon} from './assets/icons/discord.svg';

import EmailLogIn from './components/emaillogin';
import SignUp from './components/emailsignup';
import { auth } from './firebasecreds';
import AncestralCat from './components/ancestralcat';

/*
TODO

  Manage log in part where users can:
    [logout, access profile info]

  Program a list of users drug in take where inputs = {
    Total drug consumption;
    Time clean(Last used);
    When they took last drugs;
  }

  Make calendar where users can:
    [get a calendar, jot when certain events happen]

*/

function App() {

  const user = auth.currentUser;

  return (
    <div className='App'>
      <Navbar>
        <NavItem icon={<HouseIcon />} />
        <NavItem icon={<DiscordIcon />} />
        <NavItem icon={<SkullIcon />} />
        <NavItem icon={<UserIcon />}>
          <DropdownMenu></DropdownMenu>
        </NavItem>
      </Navbar>
      <section>
        { user ? (
          <AncestralCat />
          ) : (
            <><SignUp/> <EmailLogIn/></>
          )}
      </section>
    </div>
    );
}

export default App;